.content-style {

  &.-centered {
    max-width: 100%;
    padding-left: rem(32px);
    padding-right: rem(32px);
    +viewport(min, medium) {
      max-width: rem(1073px);
      margin-left: auto;
      margin-right: auto;
    }
  }

  &.-fill {
    width: 100%;
    height: 100%;
  }

  &.-narrow.-centered {
    max-width: 100%;
    padding-left: rem(32px);
    padding-right: rem(32px);
    +viewport(min, medium) {
      max-width: rem(830px);
      margin-left: auto;
      margin-right: auto;
    }
  }
}
