.horizontal-ruler {
  border: none;
  border-top: solid 1px tint(theme.colors.text, 25%);
  margin: 0;
  padding: 0;

  &.-short {
    width: rem(150px);
    max-width: 50%;
  }
}
