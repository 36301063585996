._clear {
  clear: both;
}

._collapsed-medium {
  @media (min-width: theme.settings.breakpoints.medium) {
    visibility: collapse;
  }
}

._hidden {
  display: none !important;
}

._overflow-hidden {
  overflow: hidden;
}

._relative {
  position: relative;
}
