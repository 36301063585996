.fixed-page-layout {

  $headerHeight = rem(39px);
  $largeHeaderHeight = rem(52px);

  > .header {
    z-index: 2;
    height: rem(39px);
    +viewport(min, medium) {
      height: rem(52px);
    }
  }

  > .content {
    z-index: 0;
    position: relative;
    min-height: 100vh;
    &.-margin-top {
      min-height: 'calc(100vh - %s)' % $headerHeight;
      +viewport(min, medium) {
        min-height: 'calc(100vh - %s)' % $largeHeaderHeight;
      }
    }
  }

  > .sidebar {
    z-index: 1;
    bottom: inherit;

    width: 100%;
    +viewport(min, medium) {
      width: theme.components.fixed-page-layout.sidebar.default;
    }

    margin-top: $headerHeight;
    +viewport(min, medium) {
      margin-top: $largeHeaderHeight;
    }

    transition: transform 0.25s ease, opacity 0.25s ease, visibility 0s ease 0.25s;
    opacity: 0;
    transform: translateY((0 - $headerHeight));
    +viewport(min, medium) {
      transform: translateY((0 - $largeHeaderHeight));
    }
    visibility: collapse;
    &.-show {
      opacity: 1;
      transform: translateY(-1px);
      visibility: visible;

      transition: visibility 0s, transform 0.25s ease, opacity 0.25s ease;
    }
  }
}
