.svg-icon {

  box-sizing: content-box;
  width: rem(24px);
  height: rem(24px);

  &.-stroke {
    stroke: currentColor;
  }

  &.-fill {
    fill: currentColor;
  }

  &.-back-arrow {
    left: 32px;
    width: rem(27px);
    height: rem(27px);
    +viewport(min, medium) {
      width: rem(45px);
      height: rem(45px);
    }
  }

  &.-h2 {
    background: theme.colors.primary;
    border-radius: 100%;
    width: rem(66.6px);
    height: rem(66.6px);
    +viewport(min, medium) {
      width: rem(101px);
      height: rem(101px);
    }
  }

  &.-menu-header {
    stroke: currentColor;
    .line {
      transition: transform 0.33s ease;
      transform-origin: top left;
    }
    &.-open {
      .line.-tl {
        transform: translate(12px, 12px) rotate(-135deg) translate(-1px, -3px) scaleX(0.5);
      }
      .line.-tr {
        transform: translate(12px, 12px) rotate(-45deg) translate(-1px, -9px) scaleX(0.5);
      }
      .line.-bl {
        transform: translate(12px, 12px) rotate(-315deg) translate(-1px, -15px) scaleX(0.5);
      }
      .line.-br {
        transform: translate(12px, 12px) rotate(-225deg) translate(-1px, -21px) scaleX(0.5);
      }
    }
  }

  &.-menu-item {
    width: rem(30px);
    height: rem(30px);
    background: theme.colors.primary;
    border-radius: 100%;
  }

  &.-logo-header {
    box-sizing: content-box;
    width: rem(39.89px);
    height: rem(23.99px);
    +viewport(min, medium) {
      width: rem(56.37px);
      height: rem(33.91px);
    }
  }

  &.-xl {
    width: rem(48px);
    height: @width;
  }

  &.-xxl {
    width: rem(72px);
    height: @width;
  }

  &.-topic-overview-tile {
    width: rem(72px);
    height: rem(72px);
    +viewport(min, medium) {
      width: rem(128px);
      height: rem(128px);
    }
  }

  &.-topic-overview-nudge-up {
    transform: translateY(rem(-1.5px));
  }
}
