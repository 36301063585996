.menu {
  list-style: none;
  margin: 0;
  > .item {
    min-height: rem(60px);
    &:not(:last-child) {
      @extends .border-box.-bottom;
    }

    transition: background-color 0.2s ease;
    &:hover {
      background-color: tint(theme.colors.header, 90%)
    }
  }
}
