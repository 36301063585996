.text-style {

  // buttons
  &.-button {
    font-weight: 500; // Roman
    font-size: rem(13px);
    line-height: rem(18px);
    +viewport(min, medium) {
      font-size: rem(18px);
      line-height: rem(25px);
    }
  }

  // main site title
  &.-h1 {
    font-weight: 900; // Black
    text-transform: uppercase;
    letter-spacing: rem(0.75px);
    +viewport(min, medium) {
      font-size: rem(50px);
      line-height: rem(68px);
      letter-spacing: rem(2.5px);
    }
  }

  &.-h1-header {
    font-weight: 900; // Black
    font-size: rem(15px);
    line-height: rem(20px);
    text-transform: uppercase;
    letter-spacing: rem(0.75px);
    +viewport(min, medium) {
      font-size: rem(30px);
      line-height: rem(41px);
      letter-spacing: rem(1.5px);
    }
  }

  // main site subtitle
  &.-h1-sub {
    font-weight: 600; // Medium
    +viewport(min, medium) {
      font-size: rem(20px);
      line-height: rem(27px);
    }
  }

  // page title
  &.-h2 {
    font-weight: 900; // Black
    font-size: rem(20px);
    line-height: rem(27px);
    text-transform: uppercase;
    letter-spacing: rem(1.42px);
    +viewport(min, medium) {
      font-size: rem(36px);
      line-height: rem(49px);
      letter-spacing: rem(2.5px);
    }
  }

  // page subtitle
  &.-h2-sub {
    font-weight: 500; // Roman
    font-size: rem(18px);
    line-height: rem(25px);
    +viewport(min, medium) {
      font-size: rem(22px);
      line-height: rem(30px);
    }
  }

  // section title
  &.-h3 {
    font-weight: 800; // Heavy
    font-size: rem(14px);
    line-height: rem(19px);
    +viewport(min, medium) {
      font-size: rem(18px);
      line-height: rem(23px);
    }
  }

  // paragraph text
  &.-para {
    font-weight: 400; // Book
    font-size: rem(13px);
    line-height: rem(17px);
    +viewport(min, medium) {
      font-size: rem(18px);
      line-height: rem(24px);
    }
  }

  // block captions
  &.-block-caption {
    font-weight: 400; // Book
    font-size: rem(12px);
    line-height: rem(16px);
    +viewport(min, medium) {
      font-size: rem(14px);
      line-height: rem(20px);
    }
  }

  // emphasized paragraph text
  &.-em {
    font-weight: 600; // Medium
  }

  // coloured text
  &.-color-primary {
    color: theme.colors.primary;
  }
  &.-color-primary-dark {
    color: theme.colors.primary-dark;
  }
  &.-color-text {
    color: theme.colors.text;
  }
  &.-color-white {
    color: white;
  }

  // isolated, brief bits of text
  &.-label {
    font-weight: 500; // Roman
    font-size: rem(14px);
    line-height: rem(19px);
    +viewport(min, medium) {
      font-size: rem(18px);
      line-height: rem(25px);
    }
  }

  // web links
  &.-link {
    font-weight: 500; // Roman
  }

  // main menu items
  &.-menu-item {
    font-weight: 600; // Medium
    font-size: rem(17px);
    line-height: @font-size;
  }

  &.-topic-overview-item {
    font-weight: 600; // Medium
    font-size: rem(12px);
    line-height: rem(16px);
    text-align: center;
    +viewport(min, medium) {
      font-weight: 800; // Heavy
      font-size: rem(20px);
      line-height: rem(27px);
    }
  }
}
