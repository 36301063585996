.content-item-layout {

  > .content-item.-block.-float {
    +viewport(min, medium) {
      margin-bottom: rem(20px);
      &.-left {
        float: left;
        margin-right: rem(32px);
      }
      &.-right {
        float: right;
        margin-left: rem(32px);
      }
      &.-w1-4 {
        max-width: 25%;
      }
      &.-w1-3 {
        max-width: 33.33%;
      }
      &.-w1-2 {
        max-width: 50%;
      }
      &.-w2-3 {
        max-width: 66.66%;
      }
    }
  }

  > .content-item + .content-item {
    margin-top: rem(20px);
  }

  > .content-item + .content-item.-block:not(.-float) {
    margin-top: rem(32px);
  }

  > .content-item.-block:not(.-float) + .content-item:not(.-float) {
    margin-top: rem(32px);
    +viewport(min, medium) {
      margin-top: rem(60px);
    }
  }

  > .content-item.-heading + .content-item.-heading {
    margin-top: 0;
  }

  > .content-item.-heading + .content-item.-para {
    +viewport(min, medium) {
      margin-top: rem(45px);
    }
  }

  > .content-item.-para + .content-item.-heading {
    margin-top: rem(45px);
    +viewport(min, medium) {
      margin-top: rem(70px);
    }
  }
}
