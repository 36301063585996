.list-style {

  list-style: none;

  $indent = rem(64px); // same indent as text of 'Tip' content element
  margin-left: 0;
  padding-left: 0;

  > li {
    margin-top: 1.25rem; // TODO: should be part of `.content-item`
    margin-left: $indent;
    &::before {
      display: inline-block;
      margin-left: (0 - $indent);
      width: $indent;
    }
  }

  &.-ordered {
    counter-reset: list;
    > li::before {
      counter-increment: list;
      content: counter(list, lower-alpha) ') ';

      // same as .text-style.-em.-color-primary:
      color: theme.colors.primary;
      font-weight: 600;
    }
  }

  &.-unordered {
    > li::before {
      content: '- ';
      text-align: right;
      padding-right: 1ch;
    }
  }
}
