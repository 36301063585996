.table-style {

  border-collapse: collapse;

  td,
  th,
  tr {
    border: none 1px theme.colors.text;
    padding: rem(6px) rem(12px);
    text-align: left;
  }

  &.-no-vertical-lines {
    td,
    th,
    tr {
      border-left: none 0px;
      border-right: none 0px;
    }
  }

  td,
  th {
    &:not(:last-child) {
      border-right-style: solid;
    }
  }

  tr {
    border-bottom-style: solid;
  }

  &.-border {
    border: solid 1px theme.colors.text !important;
  }

  .-border {
    border-style: solid;
  }
  .-no-border {
    border-style: none;
  }

  for $side in (top left bottom right) {
    .-border-{$side} {
      border-{$side}-style: solid !important;
    }
    .-no-border-{$side} {
      border-{$side}-style: none !important;
    }
  }

  .-border-h {
    border-left-style: solid !important;
    border-right-style: solid !important;
  }
  .-no-border-h {
    border-left-style: none !important;
    border-right-style: none !important;
  }

  .-border-v {
    border-top-style: solid !important;
    border-bottom-style: solid !important;
  }
  .-no-border-v {
    border-top-style: none !important;
    border-bottom-style: none !important;
  }
}
