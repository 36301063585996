.button-style {

  white-space: inherit;

  &.-toggle {
    background-color: #fff;
    border: solid rem(1.33px) theme.colors.primary;
    border-radius: rem(8px);
    color: theme.colors.primary;
    padding: rem(4px) rem(16px);
    +viewport(min, medium) {
      padding: rem(8px) rem(32px);
      border-width: rem(1.5px);
    }

    transition: background 0.1s ease, color 0.1s ease;
    &.-selected,
    &:hover {
      background: theme.colors.primary;
      color: #fff;
    }

    &.-selected {
      cursor: inherit;
    }
  }

  &.-boink {
    &:hover {
      animation: boink 0.33s ease;
    }
  }
}

@keyframes boink {

  0% {
    transform: none;
  }

  50% {
    transform: scale(1.075);
  }

  100% {
    transform: none;
  }
}
