/*
---
name: Colors
template: colors
tag:
 - variable
 - color
colors:
 - variable: "body"
   description: "The main background color"
   value: "#FFF"
 - variable: "text"
   description: "The default text color on the body"
   value: "#202023"
 - variable: "text-gray"
   description: "The default color for grayed-out text (e. g. for disabled items or subcaptions)"
   value: "#6A6A6A"
 - variable: "text-inverted"
   description: "The default inverted text color on the body"
   value: "#FFF"
 - variable: "action"
   description: "The main action color used for links and buttons"
   value: "#3031FE"
 - variable: "line"
   description: "The default color for lines (e. g. border lines)"
   value: "#e0e0e0"
 - variable: "success"
   description: "The color for positive feedback (e. g. confirmation messages)"
   value: "#00A685"
 - variable: "error"
   description: "The color for negative feedback (e. g. error messages)"
   value: "#DC153A"
---

Color variables that are used throughout the library are defined in the `theme.colors` hash.
By default, the following keys are defined:
*/
/*
---
name: rem
category: Functions/rem
tag:
 - function
---

Converts from `px` to `rem`, based on the assumption that `16px` are equivalent to `1rem`.

## Definition:

```stylus
rems = rem(pixels);
```
*/
/*
---
name: chooseMaxContrast
category: Functions/chooseMaxContrast
tag:
 - color
 - function
---

Given one or more foreground colors `fgs` and one background color `bg`,
returns the foreground color with the highest contrast ratio with respect to `bg`.

## Definition:

```stylus
fg = chooseMaxContrast(fgs, bg);
```

## Example:

```stylus
bg = theme.colors.footer;
fg = theme.colors.text;
fgInverted = theme.colors.text-inverted;

theme.components.color-box.footer = {
  background-color: (bg),
  color: (chooseMaxContrast((fg fgInverted), bg)),
}
```
*/
/*
---
name: Global settings
template: variables
tag:
 - variable
variables:
 - name: "text-line-height"
   description: "The line height of the body"
   default: "1.5rem"
 - name: "text-font"
   description: "The `font-family` to be used for regular text"
   default: "'Roboto', serif"
 - name: "heading-font"
   description: "The `font-family` to be used for headings (and possibly subcaptions)"
   default: "'Roboto', serif"
 - name: "text-rendering"
   description: "The `text-rendering` of the body"
   default: "optimizeLegibility"
 - name: "transition-duration"
   description: "The duration of the slide transitions"
   default: "0.3s"
 - name: "border-radius"
   description: "The default radius for borders"
   default: "2px"
---

The `theme.settings` hash defines several global settings for text formatting and styling:
*/
/*
---
name: Global settings
category: Shared/Variables
template: variables
tag:
 - variables
variables:
 - name: "text-line-height"
   description: "The line height of the body"
   default: "1.5rem"
 - name: "font-family"
   description: "The font-family of the body"
   default: "'Helvetica', sans-serif"
 - name: "text-rendering"
   description: "The text-rendering of the body"
   default: "optimizeLegibility"
 - name: "transition-duration"
   description: "The duration of the slide transitions"
   default: "0.4s"
 - name: "border-radius"
   description: "The default radius for borders"
   default: "2px"
---

These variables setups some global text formatting and styling.
*/
/*
---
name: Global Reset
category: Globals/Reset
tag:
 - reset
---

The lernetz css library has a set of reset style to have the same behaviour on every browser.
Furthermore this reset sets some global variables like:
 * body font-families
 * body line-height
 * body text-rendering
 * :root font-size for proper rem conversion
*/
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: inherit;
  font-weight: inherit;
  vertical-align: baseline;
}
:root {
  font-size: 16px;
}
html {
  font-size: 100%;
}
body {
  line-height: 1.5rem;
  color: #000;
  font-family: 'Avenir' , sans-serif;
  text-rendering: optimizeLegibility;
  background-color: #fff;
  -webkit-font-smoothing: antialiased;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
select {
  vertical-align: middle;
}
textarea {
  width: 100%;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
a {
  text-decoration: none;
  color: inherit;
}
img {
  display: block;
}
ul,
ol {
  margin-left: 1.25rem;
}
/*
---
name: Global helpers
category: Globals/Helpers
tag:
 - helper
 - box-model
 - display
 - position
 - visibility
compile: true
---

A collection of global helpers to control common styles.
Note that every global helper is perfixed with an underscore to indicate that this is a global rule according to rscss.

## Z-Index:

```jade
div._z-1 z-index: 1;
div._z-2 z-index: 2;
div._z-3 z-index: 3;
div._z-4 z-index: 4;
div._z-5 z-index: 5;
div._z-6 z-index: 6;
```

## Position:

```jade
div._absolute position: absolute;
div._relative position: relativ;
div._fixed position: fixed;
div._sticky position: sticky;
div._static position: static;
```

## Display:

```jade
div._block display: block;
div._inline-block display: inline-block;
div._inline display: inline;
div._flex display: flex;
div._inline-flex display: inline-flex;
div._table display: table;
div._table-row display: table-row;
div._table-cell display: table-cell;
div._hidden display: none;
```

## Visibility:

```jade
div._visible visibility: visible;
div._invisible visibility: hidden;
```
*/
._z-1 {
  z-index: 1;
}
._z-2 {
  z-index: 2;
}
._z-3 {
  z-index: 3;
}
._z-4 {
  z-index: 4;
}
._z-5 {
  z-index: 5;
}
._z-6 {
  z-index: 6;
}
._absolute {
  position: absolute;
}
._relative {
  position: relative;
}
._fixed {
  position: fixed;
}
._sticky {
  position: sticky;
}
._static {
  position: static;
}
._block {
 {
    padding: 0.5rem 2rem;
    border-width: 0.09375rem;
  }
  display: ;
}
._inline-block {
  display: inline-block;
}
._inline {
  display: inline;
}
._flex {
  display: flex;
}
._inline-flex {
  display: inline-flex;
}
._table {
  display: table;
}
._table-row {
  display: table-row;
}
._table-cell {
  display: table-cell;
}
._hidden {
  display: none;
}
._visible {
  visibility: visible;
}
._invisible {
  visibility: hidden;
}
/*
---
name: .abs-layout
category: Components/.abs-layout
tag:
 - component
 - layout
compile: true
---

A simple component to position an element absolute. Default is top, left

## Variants

The variants for the vertical positioning are `.-top`, `.-vcenter` and `.-bottom`.
The variants for the horizontal positioning are `.-right`, `.-hcenter` and `.-left`.
Additinally the `-center` variant will center horizontal and vertical.
Variants to stretch an item to its full width/height can be dine with `.item.-stretch`.
In some situations the abs-layout itself needs to stretch so use the stretch variant on the component. `.abs-layout.-stretch` 

```jade
div.abs-layout(style="width: 100%; height: 50px")
    div.aigis-debug.item.-center
```
```jade
div.abs-layout(style="width: 100%; height: 50px")
    div.aigis-debug.item.-top.-hcenter
```
```jade
div.abs-layout(style="width: 100%; height: 50px")
    div.aigis-debug.item.-top.-right
```
```jade
div.abs-layout(style="width: 100%; height: 50px")
    div.aigis-debug.item.-right.-vcenter
```
```jade
div.abs-layout(style="width: 100%; height: 50px")
    div.aigis-debug.item.-right.-bottom
```
```jade
div.abs-layout(style="width: 100%; height: 50px")
    div.aigis-debug.item.-bottom.-hcenter
```
```jade
div.abs-layout(style="width: 100%; height: 50px")
    div.aigis-debug.item.-bottom.-left
```
```jade
div.abs-layout(style="width: 100%; height: 50px")
    div.aigis-debug.item.-left.-vcenter
```
```jade
div.abs-layout(style="width: 100%; height: 50px")
    div.aigis-debug.item.-left.-top
```
```jade
div.abs-layout(style="width: 100%; height: 50px")
    div.aigis-debug.item.-stretch
```

## Z-Index

Every item can controll its z index with the `.-z{i}`variants.
```jade
div.abs-layout(style="width: 100%; height: 50px")
    div.aigis-debug.item.-top.-left.-z1
    div.aigis-debug.item.-top.-left.-z2
```

*/
.abs-layout {
  position: relative;
}
.abs-layout.-stretch {
  width: 100%;
  height: 100%;
}
.abs-layout > .item {
  position: absolute;
}
.abs-layout > .item.-top {
  top: 0;
}
.abs-layout > .item.-right {
  right: 0;
}
.abs-layout > .item.-bottom {
  bottom: 0;
}
.abs-layout > .item.-left {
  left: 0;
}
.abs-layout > .item.-vcenter {
  top: 50%;
  transform: translateY(-50%);
}
.abs-layout > .item.-hcenter {
  left: 50%;
  transform: translateX(-50%);
}
.abs-layout > .item.-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.abs-layout > .item.-stretch {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.abs-layout > .item.-z1 {
  z-index: 1;
}
.abs-layout > .item.-z2 {
  z-index: 2;
}
.abs-layout > .item.-z3 {
  z-index: 3;
}
.abs-layout > .item.-z4 {
  z-index: 4;
}
.abs-layout > .item.-z5 {
  z-index: 5;
}
.abs-layout > .item.-z6 {
  z-index: 6;
}
.abs-layout > .item.-z7 {
  z-index: 7;
}
.abs-layout > .item.-z8 {
  z-index: 8;
}
.abs-layout > .item.-z9 {
  z-index: 9;
}
.abs-layout > .item.-z10 {
  z-index: 10;
}
/*
---
name: .border-box
category: Components/.border-box
tag:
 - component
 - border
compile: true
---

A box to control borders on all four sides.

```jade
div.border-box.-left Some demo content
```

```jade
div.border-box.-all Some demo content
```
*/
.border-box.-top {
  border-top-style: solid;
  border-width: 1px;
  border-color: #e0e0e0;
}
.border-box.-right {
  border-right-style: solid;
  border-width: 1px;
  border-color: #e0e0e0;
}
.border-box.-bottom,
.menu > .item:not(:last-child) {
  border-bottom-style: solid;
  border-width: 1px;
  border-color: #e0e0e0;
}
.border-box.-left {
  border-left-style: solid;
  border-width: 1px;
  border-color: #e0e0e0;
}
.border-box.-all {
  border-style: solid;
  border-width: 1px;
  border-color: #e0e0e0;
}
.border-box.-top-color-primary {
  border-top-style: solid;
  border-color: #06305f;
}
.border-box.-right-color-primary {
  border-right-style: solid;
  border-color: #06305f;
}
.border-box.-bottom-color-primary {
  border-bottom-style: solid;
  border-color: #06305f;
}
.border-box.-left-color-primary {
  border-left-style: solid;
  border-color: #06305f;
}
.border-box.-all-color-primary {
  border-style: solid;
  border-color: #06305f;
}
.border-box.-top-header {
  border-top-style: solid;
  border-width: 1px;
  border-color: inherit;
}
.border-box.-right-header {
  border-right-style: solid;
  border-width: 1px;
  border-color: inherit;
}
.border-box.-bottom-header {
  border-bottom-style: solid;
  border-width: 1px;
  border-color: inherit;
}
.border-box.-left-header {
  border-left-style: solid;
  border-width: 1px;
  border-color: inherit;
}
.border-box.-all-header {
  border-style: solid;
  border-width: 1px;
  border-color: inherit;
}
.border-box.-top-thick {
  border-top-style: solid;
  border-width: 0.09375rem;
}
.border-box.-right-thick {
  border-right-style: solid;
  border-width: 0.09375rem;
}
.border-box.-bottom-thick {
  border-bottom-style: solid;
  border-width: 0.09375rem;
}
.border-box.-left-thick {
  border-left-style: solid;
  border-width: 0.09375rem;
}
.border-box.-all-thick {
  border-style: solid;
  border-width: 0.09375rem;
}
.border-box.-top-thin-medium {
  border-top-style: solid;
}
@media (min-width: 32.0625rem) {
  .border-box.-top-thin-medium {
    border-width: 0.078125rem;
  }
}
.border-box.-right-thin-medium {
  border-right-style: solid;
}
@media (min-width: 32.0625rem) {
  .border-box.-right-thin-medium {
    border-width: 0.078125rem;
  }
}
.border-box.-bottom-thin-medium {
  border-bottom-style: solid;
}
@media (min-width: 32.0625rem) {
  .border-box.-bottom-thin-medium {
    border-width: 0.078125rem;
  }
}
.border-box.-left-thin-medium {
  border-left-style: solid;
}
@media (min-width: 32.0625rem) {
  .border-box.-left-thin-medium {
    border-width: 0.078125rem;
  }
}
.border-box.-all-thin-medium {
  border-style: solid;
}
@media (min-width: 32.0625rem) {
  .border-box.-all-thin-medium {
    border-width: 0.078125rem;
  }
}
.border-box.-round {
  border-radius: 50%;
}
/*
---
name: .button-style
category: Components/.button-style
tag:
 - component
 - button
compile: true
---

A button component as a base for different button variants defined in its configuration.

```jade
button.button-style default
```

```jade
button.button-style.-disabled -disabled
```

```jade
button.button-style.-hover -hover
```

```jade
button.button-style.-main -main
```

```jade
button.button-style.-main.-hover -main -hover
```
*/
.button-style {
  appearance: none;
  background: transparent;
  border: none;
  display: inline-block;
  cursor: pointer;
  outline: none;
  white-space: nowrap;
  display: flex;
  align-items: center;
}
.button-style.-main {
  color: #fff;
  background-color: #3031fe;
  padding: 0.5rem 1rem;
}
.button-style.-main.-hover:hover {
  color: #fff;
  background-color: #1616bd;
}
.button-style.-hover {
  transition: all 0.1s;
}
.button-style.-hover:hover {
  color: #3031fe;
}
.button-style.-disabled {
  cursor: inherit;
  background-color: #b6b6b6;
}
/*
---
name: .color-box
category: Components/.color-box
tag:
 - component
 - color
compile: true
---

Controls the background and/or foreground color(s) of the styled element.

## Configuration

This component produces a variant for each key in the `theme.components.color-box` hash.
For example, you can therefore a `-footer` variants by including the following in the file `color-box.var.styl`:

```stylus
theme.components.color-box.footer = {
  background-color: theme.colors.footer,  // assuming this non-default key is defined elsewhere
  color: theme.colors.text,
};
```

## Default variants:

By default, the following `color-box` variants are defined via `theme.components.color-box`:

```jade
div(style="display:flex")
  div.color-box.-body(style="width:100px;height:100px;margin:5px;padding:5px")
    span -body
  div.color-box.-action(style="width:100px;height:100px;margin:5px;padding:5px")
    span -action
  div.color-box.-black(style="width:100px;height:100px;margin:5px;padding:5px")
    span -black
  div.color-box.-white(style="width:100px;height:100px;margin:5px;padding:5px")
    span -white
```

## See also:

* [chooseMaxContrast](../../Shared/Functions/index.html#chooseMaxContrast) &mdash;
  In order to guarantee good legibility, the foreground `color` should be chosen
  in relation to the `background-color` such that a reasonably high contrast ratio
  will result. If you are working with `theme.colors` variables instead of fixed
  color values, this can help you select the best one; see there for an example.
*/
.color-box.-body {
  background-color: #fff;
  color: #202023;
}
.color-box.-action {
  background-color: #3031fe;
  color: #fff;
}
.color-box.-white {
  background-color: #fff;
  color: #000;
}
.color-box.-black {
  background-color: #000;
  color: #fff;
}
.color-box.-start-page-header {
  background-color: transparent;
  border-color: transparent;
  color: #fff;
  transition: all 0.33s ease;
}
.color-box.-start-page-header-menu-open {
  background-color: #95bbd3;
  border-color: #fff;
  color: #fff;
  transition: all 0.33s ease;
}
.color-box.-header {
  background-color: #fff;
  border-color: #000;
  color: #000;
  transition: all 0.33s ease;
}
@supports (backdrop-filter: blur(2px)) {
  .color-box.-header {
    background-color: rgba(255,255,255,0.85);
    backdrop-filter: blur(2.5px);
  }
}
/*
---
name: .display-responsive
category: Components/.display-responsive
tag:
 - responsive
 - display
compile: true
---

Controls the display of an element depending on the current breakpoint

## Hide

`.-hide-medium` hides an element as long as the screen is wider than the breakpoint `medium`.

```jade
div.display-responsive.-hide-medium Only shown when the screen is wider than the breakpoint medium
```

## Show

`.-show-medium` show an element as long as the screen is narrower than the breakpoint `medium`.

```jade
div.display-responsive.-show-medium Only shown when the screen is narrower than the breakpoint `medium`
```

*/
@media all and (max-width: 23.5rem) {
  .display-responsive.-hide-small {
    display: none;
  }
}
@media all and (min-width: 23.5rem) {
  .display-responsive.-show-small {
    display: none;
  }
}
@media all and (max-width: 32.0625rem) {
  .display-responsive.-hide-medium {
    display: none;
  }
}
@media all and (min-width: 32.0625rem) {
  .display-responsive.-show-medium {
    display: none;
  }
}
@media all and (max-width: 67.5rem) {
  .display-responsive.-hide-large {
    display: none;
  }
}
@media all and (min-width: 67.5rem) {
  .display-responsive.-show-large {
    display: none;
  }
}
/*
---
name: .fixed-layout
category: Components/.fixed-layout
tag:
 - container
 - layout
 - fixed
---

Fixed container
You can position the container with the different variants `.-top`, `.-bottom`, `.-left`, `.-right`.
To span full width / height use `.-left` & `.-right` / `.-top` & `.-bottom`

Fixed top and full width

```jade
div.fixed-layout.-top.-left.-right.aigis-debug top left fullwidth
    
```

Fixed right and full height

```jade
div.fixed-layout.-top.-bottom.-right.aigis-debug top right fullheight
    
```

*/
.fixed-layout {
  position: fixed;
}
.fixed-layout.-top {
  top: 0;
}
.fixed-layout.-bottom {
  bottom: 0;
}
.fixed-layout.-left {
  left: 0;
}
.fixed-layout.-right {
  right: 0;
}
/*
---
name: .fixed-page-layout
category: Components/.fixed-page-layout
tag:
 - component
 - layout
 - fixed
 - page
compile: true
---

A page layout with a fixed header and optional sidebars.
With the `-margin-{top|left|right}`, `-margin-{top|left|right}-small` variants you can control the spacing.
If the content for example needs a spacing towards the header use: `.content.-margin-top`.
If the sidebar show be below the header use: `.sidebar.-left.-margin-top`.


There are variants to resize the header and sidebars to a smaller width/height: `.header.-small`, `.sidebar.-small`.
If you need a spacing on your content or sidebar you need to adjust that manually like this:
```
div.fixed-page-layout
	div.header.-small
		div.aigis-debug(style="width:100%;height:100%")  Header
	div.content.-margin-top-small Content
	div.sidebar.-left.-margin-top-small.-small
		div.aigis-debug(style="height:100%")  Left Sidebar
```

## Header with sidebars

```jade
div.fixed-page-layout
	div.header
		div.aigis-debug(style="width:100%;height:100%")  Header
	div.content.-margin-top Content
	div.sidebar.-left.-margin-top
		div.aigis-debug(style="height:100%")  Left Sidebar
	div.sidebar.-right
		div.aigis-debug(style="height:100%")  Right Sidebar
```
*/
.fixed-page-layout > .header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 3.125rem;
}
.fixed-page-layout > .header.-small {
  height: 1.875rem;
}
.fixed-page-layout > .header.-hide {
  transform: translateY(-100%);
}
.fixed-page-layout > .sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 23.4375rem;
}
.fixed-page-layout > .sidebar.-small {
  width: 3.125rem;
}
.fixed-page-layout > .sidebar.-margin-top {
  top: 3.125rem;
}
.fixed-page-layout > .sidebar.-margin-top-small {
  top: 1.875rem;
}
.fixed-page-layout > .sidebar.-left {
  left: 0;
}
.fixed-page-layout > .sidebar.-left.-hide {
  transform: translateX(-100%);
}
.fixed-page-layout > .sidebar.-right {
  right: 0;
}
.fixed-page-layout > .sidebar.-right.-hide {
  transform: translateX(100%);
}
.fixed-page-layout > .content.-margin-right {
  margin-right: 23.4375rem;
}
.fixed-page-layout > .content.-margin-left {
  margin-left: 23.4375rem;
}
.fixed-page-layout > .content.-margin-right-small {
  margin-right: 3.125rem;
}
.fixed-page-layout > .content.-margin-left-small {
  margin-left: 3.125rem;
}
.fixed-page-layout > .content.-margin-top {
  margin-top: 3.125rem;
}
.fixed-page-layout > .content.-margin-top-small {
  margin-top: 1.875rem;
}
/*
---
name: .flex-layout
category: Components/.flex-layout
tag:
 - component
 - layout
 - flexbox
compile: true
---

A layout system based on flexbox to align and distribute items horizontally and vertically.


# Alignment of items

The `-center` shortcut will center all items horizontal & vertical
```jade
div(style="height: 100px").flex-layout.-center
	div.aigis-debug Item 1
	div.aigis-debug Item 2
	div.aigis-debug Item 3
```

`-baseline` will align items based on their baseline. Works good if all the items contain text. 
```jade
div.flex-layout.-baseline
	div.aigis-debug Item
	div.aigis-debug(style="padding-top: 50px;padding-bottom: 30px;") Item 2
```

`-items-top` & `-items-bottom` will align the items either on top or bottom
```jade
div.flex-layout.-items-top
	div.aigis-debug Item 1
	div.aigis-debug(style="padding-top: 50px;padding-bottom: 30px;") Item 2
	div.aigis-debug Item 3
```
```jade
div.flex-layout.-items-bottom
	div.aigis-debug Item 1
	div.aigis-debug(style="padding-top: 50px;padding-bottom: 30px;") Item 2
	div.aigis-debug Item 3
```

`-items-center` will center the items vertically
```jade
div.flex-layout.-items-center
	div.aigis-debug Item 1
	div.aigis-debug(style="padding-top: 50px;padding-bottom: 30px;") Item 2
	div.aigis-debug Item 3
```

# Alignment of individual items

```jade
div(style="height: 100px").flex-layout
	div.aigis-debug.item.-top top
	div.aigis-debug.item.-center center
	div.aigis-debug.item.-bottom bottom
	div.aigis-debug.item.-stretch stretch
```

# Row mode

If you like a container that spans the full width with item that resize themself you can use the `-row` variant.

```jade
div.flex-layout.-row
	div.aigis-debug.item Item 1
	div.aigis-debug.item Item 2
	div.aigis-debug.item Item 3
```

If you like to fix an item based on its content width use the `-content` variant.
This is a shortcut for `-no-grow -no-shrink`. 
```jade
div.flex-layout.-row
	div.aigis-debug.item.-content
		img(src="http://via.placeholder.com/350x80")
	div.aigis-debug.item Item 2
	div.aigis-debug.item Item 3
```

You can control the sizing of the items with `-grow`, `-shrink`, `-no-grow`, `-no-shrink`.
```jade
div.flex-layout.-row
	div.aigis-debug.item.-no-shrink
		img(src="http://via.placeholder.com/350x80")
	div.aigis-debug.item.-no-grow Item 2
	div.aigis-debug.item Item 3
```


# Distribution of items

Use `-[v/h]-space-around` & `-[v/h]-space-between` to distribute the items in the flex container.

```jade
div.flex-layout.-h-space-around
	div.aigis-debug Item 1
	div.aigis-debug Item 2
	div.aigis-debug Item 3
```
```jade
div.flex-layout.-h-space-between
	div.aigis-debug Item 1
	div.aigis-debug Item 2
	div.aigis-debug Item 3
```
```jade
div(style="height: 200px").flex-layout.-v-space-around.-wrap
	div.aigis-debug Item longer 1
	div.aigis-debug Item longer 2
	div.aigis-debug Item longer 3
	div.aigis-debug Item longer 4
	div.aigis-debug Item longer 5
	div.aigis-debug Item longer 6
	div.aigis-debug Item longer 7
	div.aigis-debug Item longer 8
	div.aigis-debug Item longer 9
```
```jade
div(style="height: 200px").flex-layout.-v-space-between.-wrap
	div.aigis-debug Item longer 1
	div.aigis-debug Item longer 2
	div.aigis-debug Item longer 3
	div.aigis-debug Item longer 4
	div.aigis-debug Item longer 5
	div.aigis-debug Item longer 6
	div.aigis-debug Item longer 7
	div.aigis-debug Item longer 8
	div.aigis-debug Item longer 9
```

# Alignment of multiline items with wrap

If you have alot on items that wrap you can align the multilines with the following variants:
```jade
div.flex-layout.-wrap.-h-center
	div.aigis-debug Item longer 1
	div.aigis-debug Item longer 2
	div.aigis-debug Item longer 3
	div.aigis-debug Item longer 4
	div.aigis-debug Item longer 5
	div.aigis-debug Item longer 6
	div.aigis-debug Item longer 7
	div.aigis-debug Item longer 8
	div.aigis-debug Item longer 9
```
```jade
div.flex-layout.-wrap.-left
	div.aigis-debug Item longer 1
	div.aigis-debug Item longer 2
	div.aigis-debug Item longer 3
	div.aigis-debug Item longer 4
	div.aigis-debug Item longer 5
	div.aigis-debug Item longer 6
	div.aigis-debug Item longer 7
	div.aigis-debug Item longer 8
	div.aigis-debug Item longer 9
```
```jade
div.flex-layout.-wrap.-right
	div.aigis-debug Item longer 1
	div.aigis-debug Item longer 2
	div.aigis-debug Item longer 3
	div.aigis-debug Item longer 4
	div.aigis-debug Item longer 5
	div.aigis-debug Item longer 6
	div.aigis-debug Item longer 7
	div.aigis-debug Item longer 8
	div.aigis-debug Item longer 9
```
```jade
div(style="height: 200px").flex-layout.-wrap.-v-center
	div.aigis-debug Item longer 1
	div.aigis-debug Item longer 2
	div.aigis-debug Item longer 3
	div.aigis-debug Item longer 4
	div.aigis-debug Item longer 5
	div.aigis-debug Item longer 6
	div.aigis-debug Item longer 7
	div.aigis-debug Item longer 8
	div.aigis-debug Item longer 9
```
```jade
div(style="height: 200px").flex-layout.-wrap.-top
	div.aigis-debug Item longer 1
	div.aigis-debug Item longer 2
	div.aigis-debug Item longer 3
	div.aigis-debug Item longer 4
	div.aigis-debug Item longer 5
	div.aigis-debug Item longer 6
	div.aigis-debug Item longer 7
	div.aigis-debug Item longer 8
	div.aigis-debug Item longer 9
```
```jade
div(style="height: 200px").flex-layout.-wrap.-bottom
	div.aigis-debug Item longer 1
	div.aigis-debug Item longer 2
	div.aigis-debug Item longer 3
	div.aigis-debug Item longer 4
	div.aigis-debug Item longer 5
	div.aigis-debug Item longer 6
	div.aigis-debug Item longer 7
	div.aigis-debug Item longer 8
	div.aigis-debug Item longer 9
```




*/
.flex-layout {
  display: flex;
}
.flex-layout.-inline {
  display: flex-inline;
}
.flex-layout.-wrap {
  flex-wrap: wrap;
}
.flex-layout.-row {
  flex-wrap: nowrap;
}
.flex-layout.-row > .item {
  flex: 1 1 auto;
}
.flex-layout.-column {
  flex-direction: column;
}
.flex-layout.-center {
  justify-content: center;
  align-items: center;
  align-content: center;
}
.flex-layout.-baseline {
  align-items: baseline;
}
.flex-layout.-left {
  justify-content: flex-start;
}
.flex-layout.-right {
  justify-content: flex-end;
}
.flex-layout.-top {
  align-content: flex-start;
}
.flex-layout.-bottom {
  align-content: flex-end;
}
.flex-layout.-v-center {
  align-content: center;
}
.flex-layout.-v-space-between {
  align-content: space-between;
}
.flex-layout.-v-space-around {
  align-content: space-around;
}
.flex-layout.-h-center {
  justify-content: center;
}
.flex-layout.-h-space-between {
  justify-content: space-between;
}
.flex-layout.-h-space-around {
  justify-content: space-around;
}
.flex-layout.-items-top {
  align-items: flex-start;
}
.flex-layout.-items-bottom {
  align-items: flex-end;
}
.flex-layout.-items-center {
  align-items: center;
}
.flex-layout.-items-stretch {
  align-items: flex-stretch;
}
.flex-layout > .item.-stretch {
  align-self: stretch;
}
.flex-layout > .item.-top {
  align-self: flex-start;
}
.flex-layout > .item.-center {
  align-self: center;
}
.flex-layout > .item.-bottom {
  align-self: flex-end;
}
.flex-layout > .item.-content {
  flex: none;
}
.flex-layout > .item.-grow {
  flex-grow: 1;
}
.flex-layout > .item.-shrink {
  flex-shrink: 1;
}
.flex-layout > .item.-no-grow {
  flex-grow: 0;
}
.flex-layout > .item.-no-shrink {
  flex-shrink: 0;
}
/*
---
name: .grid-layout
category: Components/.grid-layout
tag:
 - component
 - layout
 - grid
 - responsive
 - flexbox
compile: true
---

A grid layout system based on flexbox. It provides sizing variants to size on different percentages.


## Percentage based sizing

The easiest use case is to use simple `.item -w1`, `.item -w1-2`, `.item -w1-3`, `.item -w1-4` for a percentage based side-by-side rendering.

```jade
div.grid-layout
	div.item.-w1
		div.aigis-debug item -w1
	div.item.-w1-2 
		div.aigis-debug item -w1-2
	div.item.-w1-2
		div.aigis-debug item -w1-2
	div.item.-w1-3
		div.aigis-debug item -w1-3
	div.item.-w2-3
		div.aigis-debug item -w2-3
	div.item.-w1-4
		div.aigis-debug item -w1-4
	div.item.-w1-2
		div.aigis-debug item -w1-2
	div.item.-w1-4
		div.aigis-debug item -w1-4
```

## Layouts on different devices

The breakpoints for different screen sizes are specified in the global settings `theme.settings.breakpoints` and can be overwritten in `theme.components.grid-layout.breakpoints`.
The default settings define breakpoints with labels: `.-small-X-X`, `.-medium-X-X`, `.-large-X-X`.

```jade
div.grid-layout
	div.item.-w1.-small-1-3.-medium-1-2.-large-1-4
		div.aigis-debug item -w1 -small-1-3 -medium-1-2 -large-1-4
	div.item.-w1.-small-1-3.-medium-1-2.-large-1-4
		div.aigis-debug item -w1 -small-1-3 -medium-1-2 -large-1-4
	div.item.-w1-2.-small-1-3.-medium-1-2.-large-1-4
		div.aigis-debug item -w1-2 -small-1-3 -medium-1-2 -large-1-4
	div.item.-w1-2.-small-1.-medium-1-2.-large-1-4
		div.aigis-debug item -w1-2 -small-1 -medium-1-2 -large-1-4
```


## Gutter

If you need a spacing between the columns add the `-gutter` variant.
The size of the gutter is specified in the `grid-gutter`variable.

```jade
div.grid-layout.-gutter
	div.item.-w1-4
		div.aigis-debug col -w1-4
	div.item.-w1-4
		div.aigis-debug col -w1-4
	div.item.-w1-2
		div.aigis-debug col -w1-2
```


## Reverse order

To reverse the order in which the columns are displayed, you can add the modifier class `.-reverse` to your table-grid.
The columns will be in DOM order when viewport switches to small layout.

```jade
div.grid-layout.-reverse
	div.item.-w1-4
		div.aigis-debug First column in DOM
	div.item.-w1-4
		div.aigis-debug Second column in DOM
	div.item.-w1-4
		div.aigis-debug Third column in DOM
	div.item.-w1-4
		div.aigis-debug Fourth column in DOM
```

## Vertical centering

You can vertical center with the help of the `flex-layout` component.

```jade
div.grid-layout.flex-layout
	div.item.-w1-4
		div.aigis-debug A column with a lot of content that should wrap on fixed size.
	div.item.-bottom
		div.aigis-debug bottom
	div.item.-center
		div.aigis-debug center
	div.item.-stretch
		div.aigis-debug(style="height:100%") stretch
```

*/
.grid-layout {
  display: flex;
  flex-wrap: wrap;
}
.grid-layout.-gutter {
  margin: -0.625rem -0.9375rem;
}
.grid-layout.-gutter > .item {
  padding: 0.625rem 0.9375rem;
}
.grid-layout.-reverse {
  flex-direction: row-reverse;
}
.grid-layout > .item.-w1 {
  flex: 0 0 100%;
  max-width: 100%;
}
.grid-layout > .item.-w1-2 {
  flex: 0 0 50%;
  max-width: 50%;
}
.grid-layout > .item.-w1-3 {
  flex: 0 0 33.333333333333336%;
  max-width: 33.333333333333336%;
}
.grid-layout > .item.-w2-3 {
  flex: 0 0 66.66666666666667%;
  max-width: 66.66666666666667%;
}
.grid-layout > .item.-w1-4 {
  flex: 0 0 25%;
  max-width: 25%;
}
.grid-layout > .item.-w3-4 {
  flex: 0 0 75%;
  max-width: 75%;
}
@media all and (min-width: 23.5rem) {
  .grid-layout > .item.-small-1 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .grid-layout > .item.-small-1-2 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .grid-layout > .item.-small-1-3 {
    flex: 0 0 33.333333333333336%;
    max-width: 33.333333333333336%;
  }
  .grid-layout > .item.-small-2-3 {
    flex: 0 0 66.66666666666667%;
    max-width: 66.66666666666667%;
  }
  .grid-layout > .item.-small-1-4 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .grid-layout > .item.-small-3-4 {
    flex: 0 0 75%;
    max-width: 75%;
  }
}
@media all and (min-width: 32.0625rem) {
  .grid-layout > .item.-medium-1 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .grid-layout > .item.-medium-1-2 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .grid-layout > .item.-medium-1-3 {
    flex: 0 0 33.333333333333336%;
    max-width: 33.333333333333336%;
  }
  .grid-layout > .item.-medium-2-3 {
    flex: 0 0 66.66666666666667%;
    max-width: 66.66666666666667%;
  }
  .grid-layout > .item.-medium-1-4 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .grid-layout > .item.-medium-3-4 {
    flex: 0 0 75%;
    max-width: 75%;
  }
}
@media all and (min-width: 67.5rem) {
  .grid-layout > .item.-large-1 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .grid-layout > .item.-large-1-2 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .grid-layout > .item.-large-1-3 {
    flex: 0 0 33.333333333333336%;
    max-width: 33.333333333333336%;
  }
  .grid-layout > .item.-large-2-3 {
    flex: 0 0 66.66666666666667%;
    max-width: 66.66666666666667%;
  }
  .grid-layout > .item.-large-1-4 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .grid-layout > .item.-large-3-4 {
    flex: 0 0 75%;
    max-width: 75%;
  }
}
/*
---
name: .image-responsive
category: Components/.image-responsive
tag:
 - component
 - responsive
 - image
 - media
compile: true
---

This class makes the image scaling to its max width.

```jade
img.image-responsive(src='http://www.byui.edu/images/agriculture-life-sciences/flower.jpg')
```

## Variants

`.-full` forces the picture to span to 100% of the parent

```jade
img.image-responsive.-full(src='http://www.byui.edu/images/agriculture-life-sciences/flower.jpg')
```
*/
.image-responsive {
  max-width: 100%;
  height: auto;
  display: block;
}
.image-responsive.-full {
  width: 100%;
}
.image-responsive.-inset {
  max-height: 100%;
}
/*
---
name: .max-width-layout
category: Components/.max-width-layout
tag:
 - component
 - layout
 - responsive
compile: true
---

This component will limit its content to a maximum width. Forthermore it can center its content.
Usefull for page content that should not span the full width.


## Percentage based sizing

*/
@media all and (min-width: 23.5rem) {
  .max-width-layout {
    max-width: 41.75rem;
  }
}
@media all and (min-width: 32.0625rem) {
  .max-width-layout {
    max-width: 55.75rem;
  }
}
@media all and (min-width: 67.5rem) {
  .max-width-layout {
    max-width: 68.75rem;
  }
}
.max-width-layout.-center {
  margin-left: auto;
  margin-right: auto;
}
/*
---
name: .media-responsive
category: Components/.media-responsive
tag:
 - responsive
 - media
compile: true
---

`.media-responsive` - This makes a the `.media-responsive` div a container for any media element (iframe, video, ... ) that responsive scales with a fix ratio.
The media element ( child ) needs the class `.media` to fully fill out the parents size.

The `.media-responsive` component has to predefined modifiers: `.-media-4-3`, `.-media-16-9` that define a fix ratio.

```jade
div.media-responsive.-media-16-9
	iframe.media(src="https://www.youtube.com/embed/TCd6PfxOy0Y")
```

```jade
div.media-responsive.-media-4-3
	iframe.media(src="https://www.youtube.com/embed/TCd6PfxOy0Y")
```

*/
.media-responsive {
  width: 100%;
  position: relative;
  padding-bottom: 50%;
}
.media-responsive.-media-1-1 {
  padding-bottom: 50%;
}
.media-responsive.-media-4-3 {
  padding-bottom: 75%;
}
.media-responsive.-media-16-9 {
  padding-bottom: 56.25%;
}
.media-responsive > .media {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
/*
---
name: .overflow-text
category: Components/.overflow-text
tag:
 - component
 - text
compile: true
---

This component cuts the text on a given height and adds a fadeout effect.
This is usefull for text containers that have more content than space.


```jade
div.overflow-text(style="width: 200px;height: 70px") This is a very long text that is rwaped and cut at the end.
```
*/
.overflow-text {
  overflow: hidden;
  position: relative;
}
.overflow-text:after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  height: 2.1875rem;
  background: linear-gradient(to bottom, rgba(255,255,255,0), #fff 70%);
}
/*
---
name: .shadow-box
category: Components/.shadow-box
tag:
 - component
 - shadow
compile: true
---

A box to control box-shadows with different variants

## Default variants
```jade
div.shadow-box Demo content
div.shadow-box.-inset Demo content
div.shadow-box.-zero Use zero shadow in animations
```
*/
.shadow-box {
  box-shadow: 0px 1px 6px 0px rgba(0,0,0,0.17);
}
.shadow-box.-inset {
  box-shadow: inset 0px 1px 6px 0px rgba(0,0,0,0.17);
}
.shadow-box.-zero {
  box-shadow: inset 0px 0px 0px 0px rgba(0,0,0,0.17);
}
/*
---
name: .spacer-box
category: Components/.spacer-box
tag:
 - component
 - layout
 - box-model
compile: true
---

This component is used to control spacing of a box. It mainly sets the margin and padding with a fixed set of configuration values.
The default space sizes are:
```
xxs: 2
xs: 4
s: 8
default: 16
l: 32
xl: 64`
```


To control the padding/margin for a specific space you can use the following variants:
```
-{kind}-{side}-{space}
-{kind}-{axis}-{space}
-{kind}-{space}

kind: m | p
side: top | right | bottom | left
space: xxs | xs | s | l | xl
axis: v | h
```

Or to use the `default` padding space you can use the following shortcuts:
```
-{kind}-{side}
-{kind}-{axis}
-{kind}

kind: m | p
side: top | right | bottom | left
axis: v | h
```

## Examples

Here are some example that show the usage with specific space sizes.
```jade
div.spacer-box.-m-s
	div.aigis-debug Apply s margin on all sides
```
```jade
div.spacer-box.-p-v-xl
	div.aigis-debug Apply xl padding on vertical sides
```
```jade
div.spacer-box.-p-top-l
	div.aigis-debug Apply l padding on top side
```

Here are some examples that show the usage with default space size.

```jade
div.spacer-box.-p
	div.aigis-debug Apply default padding on all sides
```
```jade
div.spacer-box.-m-h
	div.aigis-debug Apply default margin on horizontal axis
```
```jade
div.spacer-box.-m-bottom
	div.aigis-debug Apply default margin on bottom side
```

## Auto margin

For setting the vertical margin to auto the `.-auto` variant can be used.

```jade
div.spacer-box.-auto(style="width: 200px")
	div.aigis-debug Apply margin auto to left and right
```

*/
.spacer-box.-m-top-xxs {
  margin-top: 0.125rem;
}
.spacer-box.-p-top-xxs {
  padding-top: 0.125rem;
}
.spacer-box.-m-right-xxs {
  margin-right: 0.125rem;
}
.spacer-box.-p-right-xxs {
  padding-right: 0.125rem;
}
.spacer-box.-m-bottom-xxs {
  margin-bottom: 0.125rem;
}
.spacer-box.-p-bottom-xxs {
  padding-bottom: 0.125rem;
}
.spacer-box.-m-left-xxs {
  margin-left: 0.125rem;
}
.spacer-box.-p-left-xxs {
  padding-left: 0.125rem;
}
.spacer-box.-m-h-xxs {
  margin-left: 0.125rem;
  margin-right: 0.125rem;
}
.spacer-box.-m-v-xxs {
  margin-top: 0.125rem;
  margin-bottom: 0.125rem;
}
.spacer-box.-m-xxs {
  margin: 0.125rem 0.125rem;
}
.spacer-box.-p-h-xxs {
  padding-left: 0.125rem;
  padding-right: 0.125rem;
}
.spacer-box.-p-v-xxs {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
}
.spacer-box.-p-xxs {
  padding: 0.125rem 0.125rem;
}
.spacer-box.-m-top-xs {
  margin-top: 0.25rem;
}
.spacer-box.-p-top-xs {
  padding-top: 0.25rem;
}
.spacer-box.-m-right-xs {
  margin-right: 0.25rem;
}
.spacer-box.-p-right-xs {
  padding-right: 0.25rem;
}
.spacer-box.-m-bottom-xs {
  margin-bottom: 0.25rem;
}
.spacer-box.-p-bottom-xs {
  padding-bottom: 0.25rem;
}
.spacer-box.-m-left-xs {
  margin-left: 0.25rem;
}
.spacer-box.-p-left-xs {
  padding-left: 0.25rem;
}
.spacer-box.-m-h-xs {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}
.spacer-box.-m-v-xs {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}
.spacer-box.-m-xs {
  margin: 0.25rem 0.25rem;
}
.spacer-box.-p-h-xs {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.spacer-box.-p-v-xs {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.spacer-box.-p-xs {
  padding: 0.25rem 0.25rem;
}
.spacer-box.-m-top-s {
  margin-top: 0.5rem;
}
.spacer-box.-p-top-s {
  padding-top: 0.5rem;
}
.spacer-box.-m-right-s {
  margin-right: 0.5rem;
}
.spacer-box.-p-right-s {
  padding-right: 0.5rem;
}
.spacer-box.-m-bottom-s {
  margin-bottom: 0.5rem;
}
.spacer-box.-p-bottom-s {
  padding-bottom: 0.5rem;
}
.spacer-box.-m-left-s {
  margin-left: 0.5rem;
}
.spacer-box.-p-left-s {
  padding-left: 0.5rem;
}
.spacer-box.-m-h-s {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.spacer-box.-m-v-s {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.spacer-box.-m-s {
  margin: 0.5rem 0.5rem;
}
.spacer-box.-p-h-s {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.spacer-box.-p-v-s {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.spacer-box.-p-s {
  padding: 0.5rem 0.5rem;
}
.spacer-box.-m-top {
  margin-top: 1rem;
}
.spacer-box.-p-top {
  padding-top: 1rem;
}
.spacer-box.-m-right {
  margin-right: 1rem;
}
.spacer-box.-p-right {
  padding-right: 1rem;
}
.spacer-box.-m-bottom {
  margin-bottom: 1rem;
}
.spacer-box.-p-bottom {
  padding-bottom: 1rem;
}
.spacer-box.-m-left {
  margin-left: 1rem;
}
.spacer-box.-p-left {
  padding-left: 1rem;
}
.spacer-box.-m-h {
  margin-left: 1rem;
  margin-right: 1rem;
}
.spacer-box.-m-v {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.spacer-box.-m {
  margin: 1rem 1rem;
}
.spacer-box.-p-h {
  padding-left: 1rem;
  padding-right: 1rem;
}
.spacer-box.-p-v {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.spacer-box.-p {
  padding: 1rem 1rem;
}
.spacer-box.-m-top-l {
  margin-top: 2rem;
}
.spacer-box.-p-top-l {
  padding-top: 2rem;
}
.spacer-box.-m-right-l {
  margin-right: 2rem;
}
.spacer-box.-p-right-l {
  padding-right: 2rem;
}
.spacer-box.-m-bottom-l {
  margin-bottom: 2rem;
}
.spacer-box.-p-bottom-l {
  padding-bottom: 2rem;
}
.spacer-box.-m-left-l {
  margin-left: 2rem;
}
.spacer-box.-p-left-l {
  padding-left: 2rem;
}
.spacer-box.-m-h-l {
  margin-left: 2rem;
  margin-right: 2rem;
}
.spacer-box.-m-v-l {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.spacer-box.-m-l {
  margin: 2rem 2rem;
}
.spacer-box.-p-h-l {
  padding-left: 2rem;
  padding-right: 2rem;
}
.spacer-box.-p-v-l {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.spacer-box.-p-l {
  padding: 2rem 2rem;
}
.spacer-box.-m-top-xl {
  margin-top: 4rem;
}
.spacer-box.-p-top-xl {
  padding-top: 4rem;
}
.spacer-box.-m-right-xl {
  margin-right: 4rem;
}
.spacer-box.-p-right-xl {
  padding-right: 4rem;
}
.spacer-box.-m-bottom-xl {
  margin-bottom: 4rem;
}
.spacer-box.-p-bottom-xl {
  padding-bottom: 4rem;
}
.spacer-box.-m-left-xl {
  margin-left: 4rem;
}
.spacer-box.-p-left-xl {
  padding-left: 4rem;
}
.spacer-box.-m-h-xl {
  margin-left: 4rem;
  margin-right: 4rem;
}
.spacer-box.-m-v-xl {
  margin-top: 4rem;
  margin-bottom: 4rem;
}
.spacer-box.-m-xl {
  margin: 4rem 4rem;
}
.spacer-box.-p-h-xl {
  padding-left: 4rem;
  padding-right: 4rem;
}
.spacer-box.-p-v-xl {
  padding-top: 4rem;
  padding-bottom: 4rem;
}
.spacer-box.-p-xl {
  padding: 4rem 4rem;
}
.spacer-box.-auto {
  margin-left: auto;
  margin-right: auto;
}
/*
---
name: .svg-icon
category: Components/.svg-icon
template: icons
tag:
 - component
 - symbol
 - icon
 - media
 - svg
compile: true
---

Defines common SVG icon/symbol sizes and presentation styles.

## Default

The <span class="variable">-default</span> variant applies automatically (but can be specified explicitly if desired).

```jade
div(style="display: inline-block; border: solid 1px #ccc;")
  svg.svg-icon
    use(xlink:href="#stars-icon")

div(style="display: inline-block; border: solid 1px #ccc;")
  svg.svg-icon.-default
    use(xlink:href="#stars-icon")
```

## -scale

The <span class="variable">-span</span> variant will automatically fit (scale & center) the SVG icon into its parent container.

```jade
div(style="display:inline-block; border: solid 1px #ccc; width:50px; height:100px")
  svg.svg-icon.-scale
    use(xlink:href="#stars-icon")

div(style="display:inline-block; border: solid 1px #ccc; width:200px; height:100px")
  svg.svg-icon.-scale
    use(xlink:href="#stars-icon")
```


*/
.svg-icon {
  fill: currentColor;
  display: block;
}
.svg-icon.-inline {
  display: inline-block;
}
/*
---
name: .text-style
category: Components/.text-style
tag:
 - component
 - text
compile: true
---

A text styling component for different text styles

## Default heading

```jade
div.text-style Default heading
```

## H1
```jade
div.text-style.-h1 The h1 header

```

## H2
```jade
div.text-style.-h2 The h2 header
```

## H3
```jade
div.text-style.-h3 The h3 header
```

## Bold
```jade
div.text-style.-bold Bold text
```

## Align center
```jade
div.text-style.-center Centered text
```

## Align right
```jade
div.text-style.-right Right aligned text
```

*/
/*
---
name: .transform-box
category: Components/.transform-box
tag:
 - component
 - transform
 - box-model
compile: true
---

A box to control common transformations

## Rotate
```jade
div.transform-box.-rot90 Some demo content
div.transform-box.-rot180 Some demo content
div.transform-box.-rot270 Some demo content
```

## Flip
```jade
div.transform-box.-flipX Some demo content
div.transform-box.-flipY Some demo content
```
*/
.transform-box.-rot90 {
  transform: rotate(90deg);
}
.transform-box.-rot180 {
  transform: rotate(180deg);
}
.transform-box.-rot270 {
  transform: rotate(270deg);
}
.transform-box.-flipX {
  transform: scaleX(-1);
}
.transform-box.-flipY {
  transform: scaleY(-1);
}
/*
---
name: .transition-box
category: Components/.transition-box
tag:
 - component
 - transition
 - box-model
compile: true
---

A box to control transition on divs

## Enable all transitions
```jade
div.transition-box.-all All transitions enabled
```

## Control the speed of the transition
```jade
div.transition-box.-all.-slow Apply the slow duration variant
div.transition-box.-all.-fast Apply the fast duration variant
```
*/
.transition-box {
  transition-duration: 0.3s;
  transition-property: all;
}
.transition-box.-slow {
  transition-duration: 0.6s;
}
.transition-box.-fast {
  transition-duration: 0.15s;
}
._clear {
  clear: both;
}
@media (min-width: 32.0625rem) {
  ._collapsed-medium {
    visibility: collapse;
  }
}
._hidden {
  display: none !important;
}
._overflow-hidden {
  overflow: hidden;
}
._relative {
  position: relative;
}
.button-style {
  white-space: inherit;
}
.button-style.-toggle {
  background-color: #fff;
  border: solid 0.083125rem #06305f;
  border-radius: 0.5rem;
  color: #06305f;
  padding: 0.25rem 1rem;
  transition: background 0.1s ease, color 0.1s ease;
}
@media (min-width: 32.0625rem) {
  .button-style.-toggle {
    padding: 0.5rem 2rem;
    border-width: 0.09375rem;
  }
}
.button-style.-toggle.-selected,
.button-style.-toggle:hover {
  background: #06305f;
  color: #fff;
}
.button-style.-toggle.-selected {
  cursor: inherit;
}
.button-style.-boink:hover {
  animation: boink 0.33s ease;
}
@-moz-keyframes boink {
  0% {
    transform: none;
  }
  50% {
    transform: scale(1.075);
  }
  100% {
    transform: none;
  }
}
@-webkit-keyframes boink {
  0% {
    transform: none;
  }
  50% {
    transform: scale(1.075);
  }
  100% {
    transform: none;
  }
}
@-o-keyframes boink {
  0% {
    transform: none;
  }
  50% {
    transform: scale(1.075);
  }
  100% {
    transform: none;
  }
}
@keyframes boink {
  0% {
    transform: none;
  }
  50% {
    transform: scale(1.075);
  }
  100% {
    transform: none;
  }
}
@media (min-width: 32.0625rem) {
  .content-item-layout > .content-item.-block.-float {
    margin-bottom: 1.25rem;
  }
  .content-item-layout > .content-item.-block.-float.-left {
    float: left;
    margin-right: 2rem;
  }
  .content-item-layout > .content-item.-block.-float.-right {
    float: right;
    margin-left: 2rem;
  }
  .content-item-layout > .content-item.-block.-float.-w1-4 {
    max-width: 25%;
  }
  .content-item-layout > .content-item.-block.-float.-w1-3 {
    max-width: 33.33%;
  }
  .content-item-layout > .content-item.-block.-float.-w1-2 {
    max-width: 50%;
  }
  .content-item-layout > .content-item.-block.-float.-w2-3 {
    max-width: 66.66%;
  }
}
.content-item-layout > .content-item + .content-item {
  margin-top: 1.25rem;
}
.content-item-layout > .content-item + .content-item.-block:not(.-float) {
  margin-top: 2rem;
}
.content-item-layout > .content-item.-block:not(.-float) + .content-item:not(.-float) {
  margin-top: 2rem;
}
@media (min-width: 32.0625rem) {
  .content-item-layout > .content-item.-block:not(.-float) + .content-item:not(.-float) {
    margin-top: 3.75rem;
  }
}
.content-item-layout > .content-item.-heading + .content-item.-heading {
  margin-top: 0;
}
@media (min-width: 32.0625rem) {
  .content-item-layout > .content-item.-heading + .content-item.-para {
    margin-top: 2.8125rem;
  }
}
.content-item-layout > .content-item.-para + .content-item.-heading {
  margin-top: 2.8125rem;
}
@media (min-width: 32.0625rem) {
  .content-item-layout > .content-item.-para + .content-item.-heading {
    margin-top: 4.375rem;
  }
}
.content-style.-centered {
  max-width: 100%;
  padding-left: 2rem;
  padding-right: 2rem;
}
@media (min-width: 32.0625rem) {
  .content-style.-centered {
    max-width: 67.0625rem;
    margin-left: auto;
    margin-right: auto;
  }
}
.content-style.-fill {
  width: 100%;
  height: 100%;
}
.content-style.-narrow.-centered {
  max-width: 100%;
  padding-left: 2rem;
  padding-right: 2rem;
}
@media (min-width: 32.0625rem) {
  .content-style.-narrow.-centered {
    max-width: 51.875rem;
    margin-left: auto;
    margin-right: auto;
  }
}
.fixed-page-layout > .header {
  z-index: 2;
  height: 2.4375rem;
}
@media (min-width: 32.0625rem) {
  .fixed-page-layout > .header {
    height: 3.25rem;
  }
}
.fixed-page-layout > .content {
  z-index: 0;
  position: relative;
  min-height: 100vh;
}
.fixed-page-layout > .content.-margin-top {
  min-height: calc(100vh - 2.4375rem);
}
@media (min-width: 32.0625rem) {
  .fixed-page-layout > .content.-margin-top {
    min-height: calc(100vh - 3.25rem);
  }
}
.fixed-page-layout > .sidebar {
  z-index: 1;
  bottom: inherit;
  width: 100%;
  margin-top: 2.4375rem;
  transition: transform 0.25s ease, opacity 0.25s ease, visibility 0s ease 0.25s;
  opacity: 0;
  transform: translateY(-2.4375rem);
  visibility: collapse;
}
@media (min-width: 32.0625rem) {
  .fixed-page-layout > .sidebar {
    width: 23.4375rem;
  }
}
@media (min-width: 32.0625rem) {
  .fixed-page-layout > .sidebar {
    margin-top: 3.25rem;
  }
}
@media (min-width: 32.0625rem) {
  .fixed-page-layout > .sidebar {
    transform: translateY(-3.25rem);
  }
}
.fixed-page-layout > .sidebar.-show {
  opacity: 1;
  transform: translateY(-1px);
  visibility: visible;
  transition: visibility 0s, transform 0.25s ease, opacity 0.25s ease;
}
.horizontal-ruler {
  border: none;
  border-top: solid 1px #3f3f3f;
  margin: 0;
  padding: 0;
}
.horizontal-ruler.-short {
  width: 9.375rem;
  max-width: 50%;
}
.layer-box {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.layer-box.-overflow {
  overflow: visible;
}
.list-style {
  list-style: none;
  margin-left: 0;
  padding-left: 0;
}
.list-style > li {
  margin-top: 1.25rem;
  margin-left: 4rem;
}
.list-style > li::before {
  display: inline-block;
  margin-left: -4rem;
  width: 4rem;
}
.list-style.-ordered {
  counter-reset: list;
}
.list-style.-ordered > li::before {
  counter-increment: list;
  content: counter(list, lower-alpha) ') ';
  color: #06305f;
  font-weight: 600;
}
.list-style.-unordered > li::before {
  content: '- ';
  text-align: right;
  padding-right: 1ch;
}
.menu {
  list-style: none;
  margin: 0;
}
.menu > .item {
  min-height: 3.75rem;
  transition: background-color 0.2s ease;
}
.menu > .item:hover {
  background-color: #f4f8fa;
}
.parallax-container {
  perspective: 1px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow-x: hidden;
  overflow-y: auto;
}
.parallax-container > .layer {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  overflow-y: visible;
}
.svg-icon {
  box-sizing: content-box;
  width: 1.5rem;
  height: 1.5rem;
}
.svg-icon.-stroke {
  stroke: currentColor;
}
.svg-icon.-fill {
  fill: currentColor;
}
.svg-icon.-back-arrow {
  left: 32px;
  width: 1.6875rem;
  height: 1.6875rem;
}
@media (min-width: 32.0625rem) {
  .svg-icon.-back-arrow {
    width: 2.8125rem;
    height: 2.8125rem;
  }
}
.svg-icon.-h2 {
  background: #06305f;
  border-radius: 100%;
  width: 4.1625rem;
  height: 4.1625rem;
}
@media (min-width: 32.0625rem) {
  .svg-icon.-h2 {
    width: 6.3125rem;
    height: 6.3125rem;
  }
}
.svg-icon.-menu-header {
  stroke: currentColor;
}
.svg-icon.-menu-header .line {
  transition: transform 0.33s ease;
  transform-origin: top left;
}
.svg-icon.-menu-header.-open .line.-tl {
  transform: translate(12px, 12px) rotate(-135deg) translate(-1px, -3px) scaleX(0.5);
}
.svg-icon.-menu-header.-open .line.-tr {
  transform: translate(12px, 12px) rotate(-45deg) translate(-1px, -9px) scaleX(0.5);
}
.svg-icon.-menu-header.-open .line.-bl {
  transform: translate(12px, 12px) rotate(-315deg) translate(-1px, -15px) scaleX(0.5);
}
.svg-icon.-menu-header.-open .line.-br {
  transform: translate(12px, 12px) rotate(-225deg) translate(-1px, -21px) scaleX(0.5);
}
.svg-icon.-menu-item {
  width: 1.875rem;
  height: 1.875rem;
  background: #06305f;
  border-radius: 100%;
}
.svg-icon.-logo-header {
  box-sizing: content-box;
  width: 2.493125rem;
  height: 1.499375rem;
}
@media (min-width: 32.0625rem) {
  .svg-icon.-logo-header {
    width: 3.523125rem;
    height: 2.119375rem;
  }
}
.svg-icon.-xl {
  width: 3rem;
  height: 3rem;
}
.svg-icon.-xxl {
  width: 4.5rem;
  height: 4.5rem;
}
.svg-icon.-topic-overview-tile {
  width: 4.5rem;
  height: 4.5rem;
}
@media (min-width: 32.0625rem) {
  .svg-icon.-topic-overview-tile {
    width: 8rem;
    height: 8rem;
  }
}
.svg-icon.-topic-overview-nudge-up {
  transform: translateY(-0.09375rem);
}
.table-style {
  border-collapse: collapse;
}
.table-style td,
.table-style th,
.table-style tr {
  border: none 1px #000;
  padding: 0.375rem 0.75rem;
  text-align: left;
}
.table-style.-no-vertical-lines td,
.table-style.-no-vertical-lines th,
.table-style.-no-vertical-lines tr {
  border-left: none 0px;
  border-right: none 0px;
}
.table-style td:not(:last-child),
.table-style th:not(:last-child) {
  border-right-style: solid;
}
.table-style tr {
  border-bottom-style: solid;
}
.table-style.-border {
  border: solid 1px #000 !important;
}
.table-style .-border {
  border-style: solid;
}
.table-style .-no-border {
  border-style: none;
}
.table-style .-border-top {
  border-top-style: solid !important;
}
.table-style .-no-border-top {
  border-top-style: none !important;
}
.table-style .-border-left {
  border-left-style: solid !important;
}
.table-style .-no-border-left {
  border-left-style: none !important;
}
.table-style .-border-bottom {
  border-bottom-style: solid !important;
}
.table-style .-no-border-bottom {
  border-bottom-style: none !important;
}
.table-style .-border-right {
  border-right-style: solid !important;
}
.table-style .-no-border-right {
  border-right-style: none !important;
}
.table-style .-border-h {
  border-left-style: solid !important;
  border-right-style: solid !important;
}
.table-style .-no-border-h {
  border-left-style: none !important;
  border-right-style: none !important;
}
.table-style .-border-v {
  border-top-style: solid !important;
  border-bottom-style: solid !important;
}
.table-style .-no-border-v {
  border-top-style: none !important;
  border-bottom-style: none !important;
}
.text-style.-button {
  font-weight: 500;
  font-size: 0.8125rem;
  line-height: 1.125rem;
}
@media (min-width: 32.0625rem) {
  .text-style.-button {
    font-size: 1.125rem;
    line-height: 1.5625rem;
  }
}
.text-style.-h1 {
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 0.046875rem;
}
@media (min-width: 32.0625rem) {
  .text-style.-h1 {
    font-size: 3.125rem;
    line-height: 4.25rem;
    letter-spacing: 0.15625rem;
  }
}
.text-style.-h1-header {
  font-weight: 900;
  font-size: 0.9375rem;
  line-height: 1.25rem;
  text-transform: uppercase;
  letter-spacing: 0.046875rem;
}
@media (min-width: 32.0625rem) {
  .text-style.-h1-header {
    font-size: 1.875rem;
    line-height: 2.5625rem;
    letter-spacing: 0.09375rem;
  }
}
.text-style.-h1-sub {
  font-weight: 600;
}
@media (min-width: 32.0625rem) {
  .text-style.-h1-sub {
    font-size: 1.25rem;
    line-height: 1.6875rem;
  }
}
.text-style.-h2 {
  font-weight: 900;
  font-size: 1.25rem;
  line-height: 1.6875rem;
  text-transform: uppercase;
  letter-spacing: 0.08875rem;
}
@media (min-width: 32.0625rem) {
  .text-style.-h2 {
    font-size: 2.25rem;
    line-height: 3.0625rem;
    letter-spacing: 0.15625rem;
  }
}
.text-style.-h2-sub {
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.5625rem;
}
@media (min-width: 32.0625rem) {
  .text-style.-h2-sub {
    font-size: 1.375rem;
    line-height: 1.875rem;
  }
}
.text-style.-h3 {
  font-weight: 800;
  font-size: 0.875rem;
  line-height: 1.1875rem;
}
@media (min-width: 32.0625rem) {
  .text-style.-h3 {
    font-size: 1.125rem;
    line-height: 1.4375rem;
  }
}
.text-style.-para {
  font-weight: 400;
  font-size: 0.8125rem;
  line-height: 1.0625rem;
}
@media (min-width: 32.0625rem) {
  .text-style.-para {
    font-size: 1.125rem;
    line-height: 1.5rem;
  }
}
.text-style.-block-caption {
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1rem;
}
@media (min-width: 32.0625rem) {
  .text-style.-block-caption {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
}
.text-style.-em {
  font-weight: 600;
}
.text-style.-color-primary {
  color: #06305f;
}
.text-style.-color-primary-dark {
  color: #182a49;
}
.text-style.-color-text {
  color: #000;
}
.text-style.-color-white {
  color: #fff;
}
.text-style.-label {
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.1875rem;
}
@media (min-width: 32.0625rem) {
  .text-style.-label {
    font-size: 1.125rem;
    line-height: 1.5625rem;
  }
}
.text-style.-link {
  font-weight: 500;
}
.text-style.-menu-item {
  font-weight: 600;
  font-size: 1.0625rem;
  line-height: 1.0625rem;
}
.text-style.-topic-overview-item {
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1rem;
  text-align: center;
}
@media (min-width: 32.0625rem) {
  .text-style.-topic-overview-item {
    font-weight: 800;
    font-size: 1.25rem;
    line-height: 1.6875rem;
  }
}
